import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { environment } from '../environments/environment';
import { SessionExpiredGuard } from '../app/core/guards';
import { AuthRoutingModule } from './pages/auth/auth-routing.module';

let routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/landing-page/landing-page.module').then(m => m.LandingPageModule),
    data: { title: 'Inicio' },
  },
  {
    path: 'panel',
    canActivate: [SessionExpiredGuard],
    loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule),
    data: { title: 'Dashboard' },
  },
  {
    path: 'form/:id',
    loadChildren: () => import('./pages/ui-form-viewer/ui-form-viewer.module').then(m => m.UiFormViewerModule),
    data: { title: 'Form Viewer' },
  },
  {
    path: 'onboarding',
    loadComponent: () => import('./pages/onboarding/onboarding.component').then(m => m.OnboardingComponent),
    data: { title: 'Onboarding' },
    canActivate: [SessionExpiredGuard],
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: '',
  },
];

if (environment.maintenance && environment.production) {
  routes = [
    {
      path: 'maintenance',
      loadChildren: () => import('./pages/maintenance/maintenance.module').then(m => m.MaintenanceModule),
      title: 'Sitio web en mantenimiento',
    },
    {
      path: '**',
      pathMatch: 'full',
      redirectTo: '/maintenance',
    },
  ];
}

let routerImports = [RouterModule.forRoot(routes), AuthRoutingModule];

if (environment.env === 'pages') {
  routerImports = [RouterModule.forRoot(routes, { useHash: true }), AuthRoutingModule];
}

@NgModule({
  imports: routerImports,
  exports: [RouterModule],
})
export class AppRoutingModule {}

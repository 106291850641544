import { Injectable } from '@angular/core';
import { UserDataType } from '../../models/types';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LocalstorageService {
  private storageSub = new Subject<string>();

  constructor() {}

  getStorage(): Observable<any> {
    return this.storageSub.asObservable();
  }

  setItem(key: string, data: any) {
    localStorage.setItem(key, data);
    this.storageSub.next(data);
  }

  removeItem(key: string) {
    localStorage.removeItem(key);
  }

  setUserData(data: string) {
    this.setItem('dataUser', data);
  }

  getLocalUserData(): UserDataType {
    return JSON.parse(localStorage.getItem('dataUser') ?? '{}') as UserDataType;
  }

  getAccountStatus(): string | null {
    const localUserData = localStorage.getItem('dataUser');

    if (localUserData === null) {
      return null;
    }

    const userData = JSON.parse(localUserData) as UserDataType;

    return userData.account_status ?? null;
  }
}

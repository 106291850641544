import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../base/base.service';
import {
  CreateVariableResponseType,
  SortVariableRequest,
  SortVariableResponse,
  WebApiResponse,
} from '../../models/types';

@Injectable({
  providedIn: 'root',
})
export class DocumentService extends BaseService {
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  createVariable(request: any, documentId: string): WebApiResponse<CreateVariableResponseType> {
    const URI = `document/${documentId}/variable`;

    return this.put(URI, request);
  }

  createCategoryOfDocument(documentId: string, payload: any): WebApiResponse<any> {
    const URI = `documents/${documentId}/categories`;

    return this.post(URI, payload);
  }

  updateVariable(request: any, documentId: string, variableId: string): WebApiResponse<any> {
    const URI = `document/${documentId}/variable/${variableId}`;

    return this.put(URI, request);
  }

  editVariable(documentId: string, categoryId: string, variableId: string, request: any): WebApiResponse<any> {
    const URI = `documents/${documentId}/categories/${categoryId}/variables/${variableId}`;

    return this.put(URI, request);
  }
  updateConditional(request: any, documentId: string, categoryId: string, conditionalId: string): WebApiResponse<any> {
    const URI = `documents/${documentId}/categories/${categoryId}/conditionals/${conditionalId}/options/mutations`;

    return this.put(URI, request);
  }

  cloneVariable(documentId: string, categoryId: string, variableId: string, partial: string): WebApiResponse<any> {
    const URI = `documents/${documentId}/categories/${categoryId}/variables/${variableId}/partials/${partial}`;

    return this.put(URI, {});
  }

  sortVariablePosition(
    request: SortVariableRequest,
    documentId: string,
    variableId: string
  ): WebApiResponse<SortVariableResponse> {
    const URI = `document/${documentId}/variable/${variableId}/move`;

    return this.put(URI, request);
  }

  deleteVariable(documentId: string, categoryId: string, variableId: string): WebApiResponse<any> {
    const URI = `documents/${documentId}/categories/${categoryId}/variables/${variableId}`;

    return this.delete(URI);
  }

  createCategory(request: any): WebApiResponse<any> {
    const URI = 'category';

    return this.post(URI, request);
  }

  updateCategory(categoryId: string, request: any): WebApiResponse<any> {
    const URI = `category/${categoryId}`;

    return this.put(URI, request);
  }

  deleteCategory(categoryId: string): WebApiResponse<any> {
    const URI = `category/${categoryId}`;

    return this.delete(URI);
  }

  createEmptyDocument(request: any): WebApiResponse<any> {
    const URI = 'document';

    return this.post(URI, request);
  }

  updateDocument(request: any, documentId: string): WebApiResponse<any> {
    const URI = `document/${documentId}`;

    return this.put(URI, request);
  }

  updateDocumentHtmlToWord(request: any, documentId: string): WebApiResponse<any> {
    const URI = `document/${documentId}/htmltowords3`;

    return this.post(URI, request);
  }

  getDocumentObs(
    userId: string,
    start: number,
    limit: number,
    sortBy?: string | string[],
    order?: string
  ): WebApiResponse<any> {
    const params = { user_id: userId };
    const URI = `document/user/query?start=${start}&limit=${limit}&sortBy=${sortBy}&order=${order}`;

    return this.post(URI, params);
  }

  getDocumentById(documentId: string, status?: string): WebApiResponse<any> {
    const URI = `document/${documentId}/status-doc/${status}`;

    return this.get(URI);
  }

  getDocumentByIdPublic(documentId: any): WebApiResponse<any> {
    const URI = `document/${documentId}/status-doc/COMPLETED/public`;

    return this.get(URI);
  }

  deleteDocument(documentId: string): WebApiResponse<any> {
    const URI = `document/${documentId}`;

    return this.delete(URI);
  }

  convertDocument(request: any): WebApiResponse<any> {
    const URI = 'document/wordtohtml';

    return this.post(URI, request);
  }

  cloneOrTransferDocument(documentId: string, newOwner?: string): WebApiResponse<any> {
    const URI = 'document/clone';
    const dataDocument = {
      idTemplate: documentId,
      newOwner,
    };

    return this.post(URI, dataDocument);
  }

  /**
   * Get document settings
   * @param documentId
   */
  getDocumentSettings(documentId: string): WebApiResponse<any> {
    const URI = `documents/${documentId}/settings`;

    return this.get(URI);
  }

  /**
   * Set document settings
   * @param documentId
   * @param payload
   */
  setDocumentSettings(documentId: string, payload: any): WebApiResponse<any> {
    const URI = `documents/${documentId}/settings`;

    return this.post(URI, payload);
  }
}

import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { jwtDecode } from 'jwt-decode';
import { AuthService, LocalstorageService } from '../../services';

@Injectable({
  providedIn: 'root',
})
export class SessionExpiredGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private localStorageService: LocalstorageService
  ) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const userData = this.localStorageService.getLocalUserData();

    // If no user data exists, redirect to login
    if (!userData) {
      this.router.navigate(['/auth/login']);

      return false;
    }

    try {
      // If no access token exists, redirect to login
      if (!userData.access_token) {
        this.authService.logout();

        return false;
      }

      // Decode the token to check expiration
      const decodedToken: any = jwtDecode(userData.access_token);

      // Check if token has expired
      const currentTime = Date.now() / 1000;
      if (decodedToken.exp && decodedToken.exp < currentTime) {
        // Token has expired, log the user out and redirect to login
        this.authService.logout();

        return false;
      }

      // Token is valid
      return true;
    } catch (_error) {
      // If there's an error parsing the token, log the user out
      this.authService.logout();

      return false;
    }
  }
}

export const LANGUAGES = [
  {
    name: 'En',
    code: 'en',
    flag: 'assets/img/en.png',
  },
  {
    name: 'Es',
    code: 'es',
    flag: 'assets/img/es.png',
  },
];

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { jwtDecode } from 'jwt-decode';
import { TranslateService } from '@ngx-translate/core';
import { catchError } from 'rxjs/operators';
import { LocalstorageService } from '../local-storage/local-storage.service';
import { UserLoginDataType } from '../../models/types';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  data: any;
  infoToken: any;
  infoUser$!: Observable<any>;
  urlApi = environment.url_api;
  httpOptions: any = {
    headers: new HttpHeaders({
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
    }),
  };
  accountActiveStatuses = ['ACTIVE'];

  private dataGlobal: any = {};
  private infoUser: BehaviorSubject<any> = new BehaviorSubject<any>(this.dataGlobal);

  get isLoggedIn() {
    const local = localStorage.getItem('dataUser');
    if (local) {
      this.loggedIn.next(false);
    } else {
      this.loggedIn.next(true);
    }

    return this.loggedIn.asObservable();
  }

  get showMenuByLogin() {
    const local = localStorage.getItem('dataUser');
    if (local) {
      this.loggedIn.next(false);
    } else {
      this.loggedIn.next(true);
    }

    return this.loggedIn.asObservable();
  }

  constructor(
    private http: HttpClient,
    private router: Router,
    private translate: TranslateService,
    private _localstorageService: LocalstorageService
  ) {
    this.infoUser$ = this.infoUser.asObservable();
  }

  login(loginPayload: UserLoginDataType): Observable<any> {
    const URI = this.urlApi + 'auth/login';

    return this.http.post(URI, loginPayload).pipe(
      tap(() => {
        this.loggedIn.next(false);
      })
    );
  }

  async logout() {
    this._localstorageService.removeItem('dataUser');
    const currentLang = localStorage.getItem('LANGUAGE');

    if (currentLang) {
      const selectedLang = JSON.parse(currentLang);
      this.translate.use(selectedLang.code);
      this.translate.setDefaultLang(selectedLang.code);
    }

    this._localstorageService.removeItem('dataUser');
    this._localstorageService.removeItem('imgUser');

    if (currentLang) {
      this._localstorageService.setItem('LANGUAGE', currentLang);
    }

    this.loggedIn.next(true);
    await this.router.navigate(['/home']);
  }

  getUserData() {
    const localData = localStorage.getItem('dataUser') !== null ? JSON.parse(localStorage.getItem('dataUser')!) : null;

    if (localData === null) {
      return null;
    }

    this.data = jwtDecode(localData.access_token);
    const img = localStorage.getItem('imgUser') ?? null;

    this.dataGlobal = {};
    this.dataGlobal = {
      initial: this.data.user.first_name.charAt(0).toUpperCase(),
      name: this.data.user.first_name.charAt(0).toUpperCase() + this.data.user.first_name.slice(1),
      avatar: img,
    };

    return this.infoUser.next(this.dataGlobal);
  }

  updateUserData(dataUpdate: any) {
    this._localstorageService.removeItem('imgUser');
    this._localstorageService.setItem('imgUser', JSON.stringify(dataUpdate.data?.avatar?.url));

    this.dataGlobal = {};
    this.dataGlobal = {
      initial: dataUpdate.data.first_name.charAt(0).toUpperCase(),
      name: dataUpdate.data.first_name.charAt(0).toUpperCase() + dataUpdate.data.first_name.slice(1),
      avatar: dataUpdate.data?.avatar?.url,
    };

    return this.infoUser.next(this.dataGlobal);
  }

  async decodeToken() {
    const localData = localStorage.getItem('dataUser') !== null ? JSON.parse(localStorage.getItem('dataUser')!) : null;

    if (localData) {
      this.infoToken = jwtDecode(localData.access_token);

      return this.infoToken;
    } else {
      return '';
    }
  }

  refreshToken(): Observable<any> | null {
    const uri = this.urlApi + 'auth/refresh';
    const localData = localStorage.getItem('dataUser') ? JSON.parse(localStorage.getItem('dataUser')!) : null;

    if (localData === null) {
      return null;
    }

    const data = {
      user_id: localData.user_id,
      refresh_token: localData.refresh_token,
    };

    return this.http.post(uri, data);
  }

  async updateToken(data: any): Promise<void> {
    const localData = localStorage.getItem('dataUser') !== null ? JSON.parse(localStorage.getItem('dataUser')!) : null;

    if (localData === null) {
      return;
    }

    const newData = {
      access_token: data.access_token,
      error: false,
      full_name: localData.full_name,
      refresh_token: data.refresh_token,
      user_id: localData.user_id,
      menu: localData.menu,
    };

    this._localstorageService.setItem('dataUser', JSON.stringify(newData));
  }

  activateAccount(token: string): Observable<any> {
    const uri = this.urlApi + 'auth/activate-account/' + token;

    return this.http.post(uri, {});
  }

  activateAccountDashboard(email: string, token?: string, code?: string): Observable<any> {
    const uri = this.urlApi + 'auth/activate-account-client-dashboard';

    return this.http.patch(uri, {
      email,
      token: token ?? '',
      code: code ?? '',
    });
  }

  resendVerifyCode(email: string): Observable<any> {
    const uri = this.urlApi + 'auth/resend-verification-code';

    return this.http.post(uri, {
      email,
    });
  }

  getUserById(id: string): Observable<any> {
    const URI = this.urlApi + `user/${id}`;

    return this.http.get(URI, this.httpOptions).pipe(
      catchError(async err => {
        if (err.status === 401) {
          await this.logout();
        }

        this.handleError(err);
      })
    );
  }

  authenticatedUserAccountIsActive(): boolean {
    if (this._localstorageService.getAccountStatus() === null) {
      return true;
    }

    return this.accountActiveStatuses.includes(this._localstorageService.getAccountStatus() ?? '');
  }

  private handleError(err: HttpErrorResponse) {
    let errorMessage: string;

    if (err.error instanceof ErrorEvent) {
      //Deprecated. Http requests never emit an ErrorEvent
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }

    throw new Error(errorMessage);
  }
}

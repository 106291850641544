import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthLoggedGuard, SessionExpiredGuard } from '../../core/guards';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
    canActivate: [AuthLoggedGuard],
    data: { title: 'Iniciar sesión' },
  },
  /*{
    path: 'register',
    loadChildren: () => import('./ui-register/ui-register.module').then(m => m.UiRegisterModule),
    canActivate: [AuthLoggedGuard],
    data: { title: 'Registrarse' },
  },*/
  {
    path: 'recovery',
    loadChildren: () => import('./reset-password/reset-password.module').then(m => m.ResetPasswordModule),
    data: { title: 'Olvidé mi contraseña' },
  },
  {
    path: 'forgot-password/:token',
    loadChildren: () => import('./forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
    data: { title: 'Recuperar contraseña' },
  },
  {
    path: 'confirm-account/:status',
    loadChildren: () => import('./confirm-account/confirm-account.module').then(m => m.ConfirmAccountModule),
    data: { title: 'Confirmación de cuenta' },
  },
  {
    path: 'active-account',
    loadChildren: () => import('./activate-account/activate-account.module').then(m => m.ActivateAccountModule),
    data: { title: 'Activación de cuenta' },
  },
  {
    path: 'register',
    loadComponent: () => import('./register/register.component').then(m => m.RegisterComponent),
    data: { title: 'Registrarse' },
    children: [
      {
        path: 'create-account',
        loadComponent: () =>
          import('./register/forms/create-account/create-account.component').then(m => m.CreateAccountComponent),
      },
      {
        path: 'account-info',
        canActivate: [SessionExpiredGuard],
        loadComponent: () =>
          import('./register/forms/account-info/account-info.component').then(m => m.AccountInfoComponent),
      },
      {
        path: 'verify-code',
        loadComponent: () =>
          import('./register/forms/verify-code/verify-code.component').then(m => m.VerifyCodeComponent),
      },
      {
        path: '**',
        pathMatch: 'full',
        redirectTo: 'create-account',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
